<script>
import { ref } from 'vue';
 import { Thumbs } from 'swiper';

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import serverConfig   from '@/helpers/config';
import axios from 'axios';
import Swal from "sweetalert2";

SwiperCore.use([Pagination, Navigation]);

export default {
  page: {
    title: "Dettaglio prodotto",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
   setup() {
      const thumbsSwiper = ref(null);
      const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
      };
      return {
        Thumbs,
        thumbsSwiper,
        setThumbsSwiper,
      };
    },
  data() {
    return {
      title: "Dettaglio prodotto",
      UrlServer: serverConfig.EP,
      item:{},
      items: [
        {
          text: "Catalogo",
          href: "/ecommerce/products",
        },
        {
          text: "Dettaglio prodotto",
          active: true,
        },
      ],
      productDetailsWidgets: [
        {
          id: 1,
          icon: "ri-money-dollar-circle-fill",
          label: "Price",
          labelDetail: "$120.40",
        },
        {
          id: 2,
          icon: "ri-file-copy-2-fill",
          label: "No. of Orders",
          labelDetail: "2,234",
        },
        {
          id: 3,
          icon: "ri-stack-fill",
          label: "Available Stocks",
          labelDetail: "1,230",
        },
        {
          id: 4,
          icon: "ri-inbox-archive-fill",
          label: "Total Revenue",
          labelDetail: "$60,645",
        },
      ],

    };
  },
  mounted(){
    this.getItem() 
  },
  methods:{
    getItem() {
      this.data = {}
        axios.get(`${this.UrlServer}products/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data && response.data.length > 0) {
             
            this.item = response.data[0]
            /*
            if (!response.data[0].Nome){
              this.item.Nome = response.data[0].Descrizione.slice(0,50)
            }
            this.editorData = response.data[0].Descrizione
            let self = this
            setTimeout(()=>{
                
                self.getRetailPrice(self.item.CodiceGruppo, self.salesRouteUser.companyID)           
            },2000)
          
           */

          }                  
      }).catch((error) => {
              if (error == "Error: Request failed with status code 401")
              {
                console.log(error)
              } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }
      });
    },
  },
  components: {
    Layout,
    PageHeader,
    Swiper,
    SwiperSlide,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row gx-lg-5">
              <div class="col-xl-4 col-md-8 mx-auto">
                <img  :src="item.image">
                <div class="product-img-slider sticky-side-div">
                
                  <swiper
                  :modules="[Thumbs]"
                   
                    class="product-thumbnail-slider p-2 rounded bg-light"
                    
                    :navigation="{
                      nextEl: '.swiper-button-next bg-white shadow',
                      prevEl: '.swiper-button-prev bg-white shadow',
                    }"
                    :thumbs="{
                      swiper: thumbnailSwiper,
                    
                    
                    }"
                  >
                    <swiper-slide>
                      <img
                        :src="item.image"
                        alt
                        class="img-fluid d-block"
                      />
                    </swiper-slide>
                    <swiper-slide>
                      <img
                      :src="item.image"
                        alt
                        class="img-fluid d-block"
                      />
                    </swiper-slide>
                    <swiper-slide>
                      <img
                      :src="item.image"
                        alt
                        class="img-fluid d-block"
                      />
                    </swiper-slide>
                    <swiper-slide>
                      <img
                      :src="item.image"
                        alt
                        class="img-fluid d-block"
                      />
                    </swiper-slide>
                   
                  </swiper>
                   <div class="swiper-button-next"></div>
                    <div class="swiper-button-prev"></div>
                  <!-- end swiper thumbnail slide -->
                  <swiper
                    :modules="[Thumbs]"
                    class="product-nav-slider mt-2"
                    :loop="false"
                    :spaceBetween="10"
                    :slidesPerView="4"
                    :freeMode="true"
                     watch-slides-progress
                      @swiper="setThumbsSwiper"
                  >
                    <swiper-slide>
                      <div class="nav-slide-item">
                        <img
                          src="@/assets/images/emura.jpeg"
                          alt
                          class="img-fluid d-block"
                        />
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="nav-slide-item">
                        <img
                          src="@/assets/images/stylish-daikin.jpeg"
                          alt
                          class="img-fluid d-block"
                        />
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="nav-slide-item">
                        <img
                          src="@/assets/images/stylish-daikin.jpeg"
                          alt
                          class="img-fluid d-block"
                        />
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="nav-slide-item">
                        <img
                          src="@/assets/images/stylish-daikin.jpeg"
                          alt
                          class="img-fluid d-block"
                        />
                      </div>
                    </swiper-slide>
                  </swiper>
                  <!-- end swiper nav slide -->
                </div>
              </div>
              <!-- end col -->

              <div class="col-xl-8">
                <div class="mt-xl-0 mt-5">
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <h4>{{ item.Descrizione }}</h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div>
                          <a href="#" class="text-primary d-block"
                            >Daikin</a
                          >
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted">
                         
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted">
                        
                        </div>
                      </div>
                    </div>
                    <div class="flex-shrink-0">
                      
                      <!--  
                      <div>
                        <router-link
                          to="/ecommerce/add-product"
                          class="btn btn-light"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                        >
                          <i class="ri-pencil-fill align-bottom"></i>
                        </router-link>
                      </div>
                    -->
                    </div>
                  </div>

                  <div class="d-flex flex-wrap gap-2 align-items-center mt-3">
                    <!--
                    <div class="text-muted fs-16">
                      <span class="mdi mdi-star text-warning"></span>
                      <span class="mdi mdi-star text-warning"></span>
                      <span class="mdi mdi-star text-warning"></span>
                      <span class="mdi mdi-star text-warning"></span>
                      <span class="mdi mdi-star text-warning"></span>
                    </div>
                    <div class="text-muted">( 5.50k Customer Review )</div>
                    -->
                  </div>

                  <div class="row mt-4">
                    <!--
                    <div class="col-lg-3 col-sm-6" v-for="(item, index) of productDetailsWidgets" :key="index">
                      <div class="p-2 border border-dashed rounded">
                        <div class="d-flex align-items-center">
                          <div class="avatar-sm me-2">
                            <div
                              class="avatar-title rounded bg-transparent text-success fs-24"
                            >
                              <i :class="`${item.icon}`"></i>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <p class="text-muted mb-1">{{item.label}} :</p>
                            <h5 class="mb-0">{{item.labelDetail}}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    -->
                   
                  </div>

                  <div class="row">
                    <div class="col-xl-6">
                      <div class="mt-4">
                        <h5 class="fs-14">Dimensioni :</h5>
                        <!--
                        <div class="d-flex flex-wrap gap-2">
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Out of Stock"
                          >
                            <input
                              type="radio"
                              class="btn-check"
                              name="productsize-radio"
                              id="productsize-radio1"
                              disabled
                            />
                            <label
                              class="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"
                              for="productsize-radio1"
                              >S</label
                            >
                          </div>

                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="04 Items Available"
                          >
                            <input
                              type="radio"
                              class="btn-check"
                              name="productsize-radio"
                              id="productsize-radio2"
                            />
                            <label
                              class="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"
                              for="productsize-radio2"
                              >M</label
                            >
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="06 Items Available"
                          >
                            <input
                              type="radio"
                              class="btn-check"
                              name="productsize-radio"
                              id="productsize-radio3"
                            />
                            <label
                              class="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"
                              for="productsize-radio3"
                              >L</label
                            >
                          </div>

                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Out of Stock"
                          >
                            <input
                              type="radio"
                              class="btn-check"
                              name="productsize-radio"
                              id="productsize-radio4"
                              disabled
                            />
                            <label
                              class="btn btn-soft-primary avatar-xs rounded-circle p-0 d-flex justify-content-center align-items-center"
                              for="productsize-radio4"
                              >XL</label
                            >
                          </div>
                        </div>-->
                      </div>
                    </div>
                    <!-- end col -->

                    <div class="col-xl-6">
                      <div class="mt-4">
                        <h5 class="fs-14">Colori :</h5>
                        <!--<div class="d-flex flex-wrap gap-2">
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="Out of Stock"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-primary"
                              disabled
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="03 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-secondary"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="03 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-success"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="02 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-info"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="01 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-warning"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="04 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-danger"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="03 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-light"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                          <div
                            data-bs-toggle="tooltip"
                            data-bs-trigger="hover"
                            data-bs-placement="top"
                            title="04 Items Available"
                          >
                            <button
                              type="button"
                              class="btn avatar-xs p-0 d-flex align-items-center justify-content-center border rounded-circle fs-20 text-dark"
                            >
                              <i class="ri-checkbox-blank-circle-fill"></i>
                            </button>
                          </div>
                        </div>-->
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <div class="mt-4 text-muted">
                    <h5 class="fs-14">Descrizione :</h5>
                    <p>{{ item.Descrizione }}</p>
                  </div>

                  <div class="product-content mt-5">
                    <h5 class="fs-14 mb-3">Scheda tecnica :</h5>

                  </div>
                  <!-- product-content -->

                  <!-- end card body -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
